import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { apiCall } from "../../../services/apiClient";
import { FormikFieldProps } from "../../framework/formUtils";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";

interface VehicleSelectProps extends Partial<FormikFieldProps> {
    value?: string |string[];
    small?: boolean;
    required?: boolean;
    multiple?: boolean;
    selectedVehicleGroupIds?: string[];
    label?: string;
    open?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    onChange?: (id: string | string[] | null, name?: string | null) => void;
}

export const VehicleSelect = (props: VehicleSelectProps) => {
    const [vehicleOptions, setVehicleOptions] = useState<AutocompleteOption[]>([]);
    const [loading, setLoading] = useState(false);

    const mapVehicleOptions = (vehicles: VehicleListItemDto[]) => {
        setVehicleOptions(vehicles.map(v => ({
            id: v.id,
            title: `${v.brand} ${v.registerNumber}`,
            description: v.brand
        }))
        );
    };

    const { value, small, onChange, onBlur, multiple, selectedVehicleGroupIds, open, autoFocus, ...rest } = props;
    useEffect(() => {
        setLoading(true);
        void apiCall<VehicleListItemDto[]>("Vehicles", "GET")
            .then(res => {
                if(selectedVehicleGroupIds && selectedVehicleGroupIds?.length > 0){
                    const list:VehicleListItemDto[] = res.data.filter(v => selectedVehicleGroupIds.includes(v.vehicleGroupId));
                    mapVehicleOptions(list);
                }else {
                    mapVehicleOptions(res.data);
                }
            })
            .catch((err) => {
                console.error("err", err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.selectedVehicleGroupIds]);

    const handleChange = (val: AutocompleteOption | AutocompleteOption[] | null) => {
        if (Array.isArray(val)) {
            if(onChange) onChange(val.map(v => v.id));
        } else {
            if(onChange) onChange(val?.id ?? null, val?.title);
        }
    };

    return (
        <AutocompleteWrapper
            label={props.label || Translations.Vehicle}
            value={value}
            size={small ? "small" : "medium"}
            options={vehicleOptions}
            onChange={handleChange}
            onBlur={onBlur}
            loading={loading}
            required={props.required}
            multiple={multiple}
            open={open}
            autoFocus={autoFocus}
            textFieldProps={{ ...rest }}
            disabled={props.disabled}
        />
    );
};