import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderTypeItem } from "../models/orderType/orderTypeItem";
import { fetchOrderTypes } from "../services/orderTypesService";

export interface OrderTypesState {
    orderTypes: OrderTypeItem[];
}

const initialState: OrderTypesState = {
    orderTypes: [],
};

export const orderTypesSlice = createSlice({
    name: "orderTypes",
    initialState,
    reducers: {
        setOrderTypes: (state, action: PayloadAction<OrderTypeItem[]>) => {
            state.orderTypes = action.payload;
        },
    },
});

export const fetchTransportOrderTypes = createAsyncThunk<OrderTypeItem[]>(
    "orderTypes",
    async(_, thunkApi) => {
        const res = await fetchOrderTypes();
        thunkApi.dispatch(setOrderTypes(res?.data));
        return res?.data;
    }
);

export const { setOrderTypes } = orderTypesSlice.actions;

export const orderTypesReducer = orderTypesSlice.reducer;
