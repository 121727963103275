import { ThemeColor, colors } from "../../framework/theme";
import { EmployeeDetailsDto } from "../employee/employee";
import { TransportOrderListItemDto } from "./transportOrder";
import { VehicleDetailsDto } from "./vehicle";

export interface TransportPlanListQueryParameters {
    states?: TransportPlanState[];
    employeeIds?: string[];
    vehicleIds?: string[];
    timeFrameStartDate?: string;
    timeFrameEndDate?: string;
}

export interface TransportPlanListItemDto {
    id: string;
    transportId: string;
    transportPlanTemplateId: string;
    name: string;
    state: TransportPlanState;
    employee: EmployeeDetailsDto;
    vehicle: VehicleDetailsDto;
    scheduledStartDateTime: string;
    scheduledEndDateTime: string;
    orders: TransportOrderListItemDto[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TransportPlanDetailsDto extends TransportPlanListItemDto {

}

export enum TransportPlanState {
    Todo = 0,
    Planning = 1,
    Planned = 2,
    InTransport = 3,
    Completed = 4
}

// TODO translations
export const transportPlanStateNames: Readonly<Record<TransportPlanState, string>> = Object.freeze({
    [TransportPlanState.Todo]: "Suunnittelematon",
    [TransportPlanState.Planning]: "Suunnittelussa",
    [TransportPlanState.Planned]: "Suunniteltu",
    [TransportPlanState.InTransport]: "Ajossa",
    [TransportPlanState.Completed]: "Valmis"
});

interface TransportPlanStateInfo {
    value: TransportPlanState;
    name: string;
    color: string;
    colorName: ThemeColor;
}

export const transportPlanStates: readonly TransportPlanStateInfo[] = Object.freeze([
    { value: TransportPlanState.Todo, name: transportPlanStateNames[TransportPlanState.Todo], color: colors.fuchsia.main, colorName: "fuchsia" },
    { value: TransportPlanState.Planning, name: transportPlanStateNames[TransportPlanState.Planning], color: colors.orange.main, colorName: "orange" },
    { value: TransportPlanState.Planned, name: transportPlanStateNames[TransportPlanState.Planned], color: colors.yellow.main, colorName: "yellow" },
    { value: TransportPlanState.InTransport, name: transportPlanStateNames[TransportPlanState.InTransport], color: colors.blue.main, colorName: "blue" },
    { value: TransportPlanState.Completed, name: transportPlanStateNames[TransportPlanState.Completed], color: colors.green.main, colorName: "green" }
]);

export interface TransportPlanUpdateDto {
    id?: string;
    name: string;
    employeeId?: string;
    vehicleId?: string;
    billingCustomerId?: string;
    senderCustomerId?: string;
    receiverCustomerId?: string;
    scheduledStartDateTime?: string;
    scheduledEndDateTime?: string;
}

enum TransportTaskType {
    Load = 0,
    Unload = 1
}

export interface TransportPlanTaskCreateDto {
    transportOrderId: string;
    scheduledNextTask?: string;
    TaskType?: TransportTaskType;
}
