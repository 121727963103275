/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useFormik } from "formik";
import React from "react";

import {
    Button,
    MenuItem,
    Select,
    styled,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import { TransportOrderStateIndicator } from "../components/transportUtils";
import { TransportOrderState, transportOrderStateNames } from "../../../models/transport/transportOrder";
import { MuiDatePicker } from "../../framework/muiDatepicker";
import CircleIcon from "@mui/icons-material/Circle";
import {
    formikFieldProps,
    formikValidationProps,
} from "../../framework/formUtils";
import { DriverSelect } from "../components/driverSelect";
import { TransportPlanUpdateDto } from "../../../models/transport/transportPlan";
import { VehicleSelect } from "../components/vehicleSelect";
import { CustomerSelect } from "../components/customerSelect";
import { colors } from "../../../framework/theme";
import { useTranslation } from "react-i18next";

export type UseFormikType = ReturnType<typeof useFormik>;

interface Props {
    formik: UseFormikType;
    isEdit?: boolean;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
    "&:last-child td, &:last-child th": { border: 0 },
    "& .MuiFormHelperText-root": {
        display: "none",
    },
    "& .mui-datepicker": {
        maxWidth: "100px",
    },
    "& .MuiAutocomplete-input": {
        padding: "13px 4px!important",
    },
    "& .mui-custom-input .MuiOutlinedInput-input": {
        height: "30px",
    },
    "& .mui-custom-input.MuiOutlinedInput-input": {
        height: "33px",
    },
    selected: false,
}));

const TableCellStyled = styled(TableCell)`
     {
        width: 50px;
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const TransportOrdersMainForm = ({ formik, isEdit }: Props) => {
    const { values, handleChange, errors } = formik;
    const { t } = useTranslation();

    const fieldProps = (field: keyof TransportPlanUpdateDto) =>
        formikFieldProps(formik, field);

    return (
        <StyledTableRow
            className="transport-orders-main-form"
            sx={{ padding: "80px 0" }}
        >
            <TableCell sx={{ maxWidth: "50px!important", width: "50px" }}>
                {isEdit ? (
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="state"
                        value={values.state || 0}
                        label="Age"
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value={TransportOrderState.OrderCreated}>
                            <CircleIcon
                                fontSize="small"
                                htmlColor={colors.orange.main}
                            />
                            <span>{t(transportOrderStateNames[TransportOrderState.OrderCreated])}</span>
                        </MenuItem>
                        <MenuItem value={TransportOrderState.PickedUp}>
                            <CircleIcon
                                fontSize="small"
                                htmlColor={colors.blue.main}
                            />
                            <span>{t(transportOrderStateNames[TransportOrderState.PickedUp])}</span>
                        </MenuItem>
                        <MenuItem value={TransportOrderState.Delivered}>
                            <CircleIcon
                                fontSize="small"
                                htmlColor={colors.green.main}
                            />
                            <span>{t(transportOrderStateNames[TransportOrderState.Delivered])}</span>
                        </MenuItem>
                    </Select>
                ) : (
                    <TransportOrderStateIndicator
                        state={TransportOrderState.OrderCreated}
                    />
                )}
            </TableCell>
            <TableCell />
            <TableCell sx={{ width: "150px!important" }}>
                <TextField
                    fullWidth
                    value={values.name}
                    className="mui-custom-input"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    error={!!errors.name}
                    helperText={errors.name as string}
                />
            </TableCell>
            <TableCell sx={{ maxWidth: "200px!important" }}>
                <CustomerSelect
                    helperText=" "
                    name="senderCustomerId"
                    value={values.senderCustomerId}
                    label=""
                    {...fieldProps("senderCustomerId")}
                    disabled={false}
                />
            </TableCell>
            <TableCell sx={{ maxWidth: "200px!important" }}>
                <CustomerSelect
                    helperText=" "
                    name="receiverCustomerId"
                    value={values.receiverCustomerId}
                    label=""
                    {...fieldProps("receiverCustomerId")}
                    disabled={false}
                />
            </TableCell>
            <TableCell sx={{ maxWidth: "200px!important" }}>
                <CustomerSelect
                    helperText=" "
                    name="billingCustomerId"
                    value={values.billingCustomerId}
                    label=""
                    {...fieldProps("billingCustomerId")}
                    disabled={false}
                />
            </TableCell>
            <TableCellStyled sx={{ maxWidth: "200px!important" }}>
                <VehicleSelect
                    {...fieldProps("vehicleId")}
                    label=""
                    value={values.vehicleId}
                    disabled={false}
                    error={!!errors.vehicleId}
                    helperText={errors.vehicleId as string}
                />
            </TableCellStyled>
            <TableCellStyled sx={{ maxWidth: "200px!important" }}>
                <DriverSelect
                    {...fieldProps("employeeId")}
                    label=""
                    disabled={false}
                    error={!!errors.employeeId}
                    helperText={errors.employeeId as string}
                />
            </TableCellStyled>
            <TableCell>
                <MuiDatePicker
                    className="mui-custom-input"
                    value={
                        formik.values.scheduledStartDateTime
                            ? new Date(formik.values.scheduledStartDateTime)
                            : null
                    }
                    onChange={(val) => {
                        void formik.setFieldValue(
                            "scheduledStartDateTime",
                            val?.toISOString() ?? null
                        );
                    }}
                    onBlur={() => {
                        void formik.setFieldTouched(
                            "scheduledStartDateTime",
                            true,
                            true
                        );
                        void formik.validateField("scheduledStartDateTime");
                    }}
                    showTimeSelect
                    disabled={false}
                    extraProps={{
                        textFieldProps: {
                            required: true,
                            ...formikValidationProps(
                                formik,
                                "scheduledStartDateTime"
                            ),
                        },
                    }}
                />
            </TableCell>
            <TableCell
                sx={{
                    width: 100,
                }}
            >
                <MuiDatePicker
                    className="mui-custom-input"
                    value={
                        formik.values.scheduledEndDateTime
                            ? new Date(formik.values.scheduledEndDateTime)
                            : null
                    }
                    onChange={(val) => {
                        void formik.setFieldValue(
                            "scheduledEndDateTime",
                            val?.toISOString() ?? null
                        );
                    }}
                    onBlur={() => {
                        void formik.setFieldTouched(
                            "scheduledEndDateTime",
                            true,
                            true
                        );
                        void formik.validateField("scheduledEndDateTime");
                    }}
                    showTimeSelect
                    disabled={false}
                    extraProps={{
                        textFieldProps: {
                            required: true,
                            ...formikValidationProps(
                                formik,
                                "scheduledEndDateTime"
                            ),
                        },
                    }}
                />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell sx={{ maxWidth: "140px" }}>
                <Button variant="save" type="submit">
                    {t("common.save")}
                </Button>
            </TableCell>
        </StyledTableRow>
    );
};

export default TransportOrdersMainForm;
