import { IconButton, Typography, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";

import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../../../framework/customStore";

import { fetchEmployees } from "../../../store/employeeSlice";

import { TextareaEditor } from "../../framework/textareaEditor";
import { Base } from "../../../framework/base";
import { defaultTableOrderItem } from "../TransportOrdersMain";
import { editTransportOrder } from "../../../services/transportOrderService";
import { Translations } from "../../../models/translations";

interface ISidebarOrderDetailsProps {
    onClose: () => void;
    sidebarOrdersDataChanged?: boolean;
    setSidebarOrdersDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    transportOrdersView?: boolean;
}

export const SidebarOrderDetails = ({ onClose }: ISidebarOrderDetailsProps) => {
    const dispatch = useAppDispatch();
    const order = useAppSelector(
        (state: RootState) =>
            state.transportVehicles
                .sideBarOrderDetails as TransportOrderListItemDto
    );

    const employees = useAppSelector((state) => state.employees.employees);

    useEffect(() => {
        if (employees.length === 0) {
            dispatch(fetchEmployees() as any);
        }
    }, [employees]);

    const handleSubmit = async(transportDetails: string) => {
        const newTransport = Base.cloneClassObject(defaultTableOrderItem);
        for (const [key] of Object.entries(defaultTableOrderItem)) {
            newTransport[key] = order[key];
        }
        newTransport.transportDetails = transportDetails;
        delete Object.assign(newTransport, {
            ["modifiedEmployeeId"]: newTransport["createdEmployeeId"],
        })["createdEmployeeId"];
        await editTransportOrder(newTransport, String(order.id)).then(() => {});
        store.customStore.dispatch(
            StoreActions.showSuccessMessage(Translations.SaveSuccess)
        );
        onClose();
    };

    if (!order) return null;

    return (
        <Stack spacing={2} sx={{ width: "100%" }} fontSize="small">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h2">Lisätiedot</Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="small"
                        onClick={() => onClose()}
                        key="btn-copy"
                    >
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
            </Stack>

            <div>
                <TextareaEditor
                    disabled={false}
                    value={order.transportDetails}
                    maxLength={1000}
                    onSave={(transportDetails: string) =>
                        void handleSubmit(transportDetails)
                    }
                />
            </div>
        </Stack>
    );
};
