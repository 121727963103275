import { ThemeColor, colors } from "../../framework/theme";
import {TransportOrderProductListItemDto} from "./transportOrderProduct";

export interface TransportOrderListQueryParameters {
    invoicingState?: TransportOrderInvoicingState;
    invoicingStates?: TransportOrderInvoicingState[];
    senderCustomerId?: string;
    receiverCustomerId?: string;
    billingCustomerId?: string;
    transportPlanId?: string;
    noTransportPlan?: boolean;
    deliveryZipCode?: string;
    deliveryCity?: string;
    pickupZipCode?: string;
    pickupCity?: string;
    pickupTimeFrameStart?: string;
    pickupTimeFrameEnd?: string;
    deliveryTimeFrameStart?: string;
    deliveryTimeFrameEnd?: string;
    timeFrameStartDate?: string;
    timeFrameEndDate?: string;
    keyword?: string;
    selectedOrderStates?: TransportOrderState[];
}

export interface TransportOrderListItemDto {
    id: string | null;
    transportPlanId?: string;
    transportId?: string;
    name?: string;
    state: TransportOrderState;
    actualDeliveryDateTime?: string;
    //deliveryStartDateTime?: string;
    //deliveryEndDateTime?: string;
    //deliveryAddress?: string;
    //deliveryZipCode?: string;
    //deliveryCity?: string;
    //deliveryCountry?: string;
    //deliveryDetails?: string;
    //pickUpStartDateTime?: string;
    //pickUpEndDateTime?: string;
    //pickUpAddress?: string;
    //pickUpZipCode?: string;
    //pickUpCity?: string;
    //pickUpCountry?: string;
    //pickUpDetails?: string;
    //waybillNumber?: string;
    orderNumber?: number;
    invoicingState?: TransportOrderInvoicingState;
    fixedPrice?: number;
    invoiceNewId?: string;
    senderCustomerId?: string;
    senderName?: string;
    senderBusinessId?: string;
    //senderPhoneNumber?: string;
    receiverCustomerId?: string;
    receiverName?: string;
    receiverBusinessId?: string;
    //receiverPhoneNumber?: string;
    billingCustomerName?: string;
    billingCustomerId?: string;
    formData?: string;
    formValues?: string;
    formTitle?: string;
    orderTypeId?: string;
    driverComments?: string;
    createdEmployeeId: string;
    createdDateTime: string;
    modifiedEmployeeId: string;
    modifiedDateTime: string;
    vehicleId?: string;
    vehicleName?: string;
    employeeId?: string;
    employeeName?: string;
    scheduledStartDateTime?: string;
    scheduledEndDateTime?: string;
    transportDetails?: string;
    type?: string;
    //Customer: string;
    products?: TransportOrderProductListItemDto[];
}

export interface TransportOrderItem {
    name: string;
    vehicleId: string;
    employeeId: string;
    scheduledStartDateTime: string;
    scheduledEndDateTime: string;
    state: TransportOrderState;
    transportDetails: string;
    senderBusinessId?: string;
    senderCustomerId: string;
    orderTypeId: string;
    receiverBusinessId?: string;
    receiverCustomerId: string;
    driverComments: string;
    billingCustomerId: string;
    fixedPrice: number;
    createdEmployeeId: string;
    products: { id: string; quantity: number }[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TransportOrderDetailsDto extends TransportOrderListItemDto {}

export interface TransportOrderInvoicingStateChangeDto {
    invoicingState: TransportOrderInvoicingState;
}

export enum TransportOrderInvoicingState {
    NotInvoiceable,
    Invoiceable,
    InvoicedInternally,
    AddedToInvoice,
    InvoicedExternally,
}

export enum TransportOrderState {
    //OrderReceived = 0,
    //OrderCreated = 1,
    //Planned = 2,
    //PickedUp = 3,
    //Delivered = 4
    OrderCreated = 0,
    PickedUp = 1,
    Delivered = 2
}

export const transportOrderStateNames: Readonly<Record<TransportOrderState, string>> = Object.freeze({
    [TransportOrderState.OrderCreated]: "transport.orderState.orderCreated",
    [TransportOrderState.PickedUp]: "transport.orderState.pickedUp",
    [TransportOrderState.Delivered]: "transport.orderState.delivered",
});

interface transportOrderStateInfo {
    value: TransportOrderState;
    name: string;
    color: string;
    colorName: ThemeColor;
    colorDark: string;
}

export const transportOrderStates: readonly transportOrderStateInfo[] =
    Object.freeze([
        //{ value: TransportOrderState.OrderReceived, name: "Tilaus vastaanotettu", color: colors.fuchsia.main, colorName: "fuchsia", colorDark: colors.fuchsia.dark },
        {
            value: TransportOrderState.OrderCreated,
            name: "Tilaus luotu",
            color: colors.orange.main,
            colorName: "orange",
            colorDark: colors.orange.dark,
        },
        //{ value: TransportOrderState.Planned, name: "Suunniteltu", color: colors.yellow.main, colorName: "yellow", colorDark: colors.yellow.dark },
        {
            value: TransportOrderState.PickedUp,
            name: "Noudettu",
            color: colors.blue.main,
            colorName: "blue",
            colorDark: colors.blue.dark,
        },
        {
            value: TransportOrderState.Delivered,
            name: "Toimitettu",
            color: colors.green.main,
            colorName: "green",
            colorDark: colors.green.dark,
        },
    ]);
