import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { TransportOrderDetailsDto } from "../../../models/transport/transportOrder";
import { apiCall } from "../../../services/apiClient";
import { showApiError } from "../../framework/formUtils";
import { MainLayout } from "../../layout/mainLayout";
import { TransportOrderForm } from "./transportOrderForm";

export const TransportOrderMain = (props: RouteComponentProps<{id: string}>) => {
    const id = props.match.params.id;
    const [order, setOrder] = useState<TransportOrderDetailsDto | null>(null);

    useEffect(() => {
        if (id === "new") {
            setOrder({
                id: null,
                name: "",
                state: null,

                //deliveryStartDateTime: null,
                //deliveryEndDateTime: null,
                //deliveryAddress: "",
                //deliveryZipCode: "",
                //deliveryCity: "",
                //deliveryCountry: "",
                //deliveryDetails: "",

                fixedPrice: 0,

                //pickUpStartDateTime: null,
                //pickUpEndDateTime: null,
                //pickUpAddress: "",
                //pickUpZipCode: "",
                //pickUpCity: "",
                //pickUpCountry: "",
                //pickUpDetails: "",

                //waybillNumber: null,

                //senderCustomerId: null,
                //senderName: null,
                //senderPhoneNumber: null,
                //senderBusinessId: null,

                receiverCustomerId: null,
                receiverName: null,
                //receiverPhoneNumber: null,
                //receiverBusinessId: null,

                createdEmployeeId: null,
                createdDateTime: null,
                modifiedEmployeeId: null,
                modifiedDateTime: null,

                vehicleId: null,
                vehicleName: null,
                employeeId: null,
                employeeName: null,
                scheduledStartDateTime: null,
                scheduledEndDateTime: null,
                transportDetails: null,
                type: null
            });
        } else {
            apiCall<TransportOrderDetailsDto>(`TransportOrders/${id}`, "GET")
                .then(res => {
                    setOrder(res.data);
                })
                .catch(showApiError);
        }

    }, []);

    return (
        <MainLayout>
            {order && <TransportOrderForm order={order} />}
        </MainLayout>
    );
};




