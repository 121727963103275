/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
    Button,
    debounce,
    IconButton,
    TextField,
    Tooltip,
    useTheme
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Stack } from "@mui/system";
import i18n from "i18next";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { Translations } from "../../models/translations";
import {
    TransportOrderInvoicingState,
    TransportOrderItem,
    TransportOrderListItemDto,
    TransportOrderListQueryParameters,
    TransportOrderState,
    transportOrderStates,
} from "../../models/transport/transportOrder";
import { apiCall } from "../../services/apiClient";
import {
    resetOrderFilters,
    setKeyword,
    setVehicle,
    setTimeRange,
    toggleOrderStateSelected,
    setSenderCustomer,
    setOrderType,
} from "../../store/transport/transportOrdersSlice";
import { updateOrdersListShownColumns } from "../../store/transport/transportOrdersColumnsSlice";
import { MuiDateRangePicker } from "../framework/muiDatepicker";
import { MuiSwitch } from "../framework/muiSwitch";
import { TableDef, TableWrapper } from "../framework/tableWrapper";
import { MainLayout } from "../layout/mainLayout";
import { showError } from "../storage/storageUtils";
import { CustomerSelect } from "./components/customerSelect";
import {
    getDateTime,
    getEmployeeName,
    TransportOrderStateIndicator,
} from "./components/transportUtils";
import { fetchEmployees } from "../../store/employeeSlice";
import { SidebarOrderDetails } from "./components/sidebarOrderDetails";
import { setSideBarOrderDetails } from "../../store/transport/transportVehiclesSlice";
import TransportOrdersMainForm from "./transportOrder/TransportOrdersMainForm";
import {
    addTransportOrder,
    deleteTransportOrder,
    editTransportOrder,
} from "../../services/transportOrderService";
import { transportPlanValidationSchema } from "../../framework/schemas/transportPlanValidationSchema";
import { ConfirmationDialog } from "../framework/confirmationDialog";
import { VehicleSelect } from "./components/vehicleSelect";
import { SidebarOrderType } from "./components/sidebarOrderType";
import { SidebarOrderProducts } from "./components/sidebarOrderProducts";
import MuiMenu from "../framework/muiMenu";
import { OrderTypeSelect } from "./components/orderTypeSelect";

export const TransportOrdersMain = () => {
    const { t } = useTranslation();
    const [orderItems, setOrderItems] = useState<TransportOrderListItemDto[]>(
        []
    );
    const employees = useAppSelector((state) => state.employees.employees);
    const [addForm, setAddForm] = useState<boolean>(false);
    const [sidebarOrderProducts, setSidebarOrderProducts] = useState<
        TransportOrderListItemDto | undefined
    >(undefined);
    const [sidebarOrderTypeDetails, setSidebarOrderTypeDetails] = useState<
        TransportOrderListItemDto | undefined
    >(undefined);
    const [rowForEdit, setRowForEdit] =
        useState<TransportOrderListItemDto | null>(null);

    const state = useAppSelector((state) => state.transportOrders);
    const dispatch = useAppDispatch();
    const ordersDataChanged = useAppSelector(
        (state) => state.transportOrders.ordersDataChanged
    );
    const sideBarOrderDetails = useAppSelector(
        (state) => state.transportVehicles.sideBarOrderDetails
    );

    const fetchOrders = () => {
        const selectedOrderStates = transportOrderStates
            .map((tos) => tos.value)
            .filter((tos) => state.selectedOrderStates[tos]);

        void apiCall<
            TransportOrderListItemDto[],
            TransportOrderListQueryParameters
        >("TransportOrders", "GET", {
            ...(state.timeRange[0] && {
                scheduledStartDateTimeStart: state.timeRange[0].toISOString(),
            }),
            ...(state.timeRange[1] && {
                scheduledStartDateTimeEnd: state.timeRange[1].toISOString(),
            }),
            ...(state.createdRange[0] && {
                createdDateTimeStart: state.createdRange[0].toISOString(),
            }),
            ...(state.createdRange[1] && {
                createdDateTimeEnd: state.createdRange[1].toISOString(),
            }),
            ...(state.senderCustomer && {
                senderCustomerId: state.senderCustomer,
            }),
            ...(state.receiverCustomer && {
                receiverCustomerId: state.receiverCustomer,
            }),
            ...(state.billingCustomerId && {
                billingCustomerId: state.billingCustomerId,
            }),
            ...(state.vehicleId && {
                vehicleId: state.vehicleId,
            }),
            ...(state.orderType && {
                orderTypeId: state.orderType,
            }),
            ...(state.keyword && { keyword: state.keyword }),
            ...(selectedOrderStates.length < transportOrderStates.length && {
                selectedOrderStates,
            }),
        })
            .then((res) => {
                setOrderItems(res.data);
            })
            .catch(showError);
    };

    useEffect(fetchOrders, [
        state.timeRange,
        state.createdRange,
        state.senderCustomer,
        state.receiverCustomer,
        state.selectedOrderStates,
        state.billingCustomerId,
        state.keyword,
        state.vehicleId,
        state.orderType,
        ordersDataChanged,
    ]);

    useEffect(() => {
        if (employees.length === 0) {
            dispatch(fetchEmployees() as any);
        }
    }, [employees]);

    function toggleState(toggledState: TransportOrderState) {
        dispatch(toggleOrderStateSelected(toggledState));
    }

    const debouncedSetKeyword = debounce((value: string) => {
        dispatch(setKeyword(value));
    }, 1000);

    return (
        <MainLayout
            topComponent={
                <div>
                    <Grid2 container spacing={2}>
                        <Grid2>
                            <MuiDateRangePicker
                                labels={[t("common.timeperiodStarts"), t("common.timeperiodEnds")]}
                                value={state.timeRange}
                                onChange={(val) => dispatch(setTimeRange(val))}
                            />
                        </Grid2>
                        <Grid2>
                            <VehicleSelect
                                value={state.vehicleId}
                                onChange={(val: string) =>
                                    dispatch(setVehicle(val))
                                }
                            />
                        </Grid2>
                        <Grid2>
                            <CustomerSelect
                                label={t("transport.order.customer")}
                                value={state.senderCustomer}
                                onChange={(val: string) =>
                                    dispatch(setSenderCustomer(val))
                                }
                            />
                        </Grid2>
                        <Grid2>
                            <OrderTypeSelect
                                label={t("common.type")}
                                value={state.orderType}
                                onChange={(val: string) =>
                                    dispatch(setOrderType(val))
                                }
                            />
                        </Grid2>
                        <Grid2>
                            <TextField
                                label="Hae"
                                onChange={(e) =>
                                    debouncedSetKeyword(e.currentTarget.value)
                                }
                            />
                        </Grid2>
                        <Grid2
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid2 container>
                                {transportOrderStates.map((tos) => (
                                    <MuiSwitch
                                        key={tos.value}
                                        checked={
                                            state.selectedOrderStates[tos.value]
                                        }
                                        onChange={() => toggleState(tos.value)}
                                        color={tos.colorName}
                                        label={tos.name}
                                    />
                                ))}
                            </Grid2>
                        </Grid2>
                        <Grid2
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ marginLeft: "auto" }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    dispatch(resetOrderFilters());
                                }}
                            >
                                {t("common.clearFilters")}
                            </Button>
                        </Grid2>
                    </Grid2>
                </div>
            }
        >
            <>
                <Grid2 container spacing={2}>
                    <Grid2 xs>
                        <div style={{ minHeight: "600px" }}>
                            <OrdersTable
                                addForm={addForm}
                                orders={orderItems}
                                rowForEdit={rowForEdit}
                                setRowForEdit={setRowForEdit}
                                setAddForm={setAddForm}
                                fetchOrders={fetchOrders}
                                selectedSidebar={
                                    sideBarOrderDetails
                                        ? sideBarOrderDetails.id
                                        : undefined
                                }
                                handleOrderProducts={(row) =>
                                    setSidebarOrderProducts(row)
                                }
                                handleOrderType={(row) =>
                                    setSidebarOrderTypeDetails(row)
                                }
                                handleOrderDetails={(row) =>
                                    dispatch(setSideBarOrderDetails(row))
                                }
                            />
                        </div>
                    </Grid2>

                    {sidebarOrderProducts && (
                        <Grid2 xs={3}>
                            <Box
                                fontSize="small"
                                sx={{
                                    p: 2,
                                    backgroundColor: "lightBlue.main",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <SidebarOrderProducts
                                    transportOrderId={sidebarOrderProducts.id}
                                    onClose={() => {
                                        setSidebarOrderProducts(undefined);
                                        fetchOrders();
                                    }}
                                />
                            </Box>
                        </Grid2>
                    )}

                    {sidebarOrderTypeDetails && (
                        <Grid2 xs={3}>
                            <Box
                                fontSize="small"
                                sx={{
                                    p: 2,
                                    backgroundColor: "lightBlue.main",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <SidebarOrderType
                                    transportOrdersView
                                    orderTypeId={sidebarOrderTypeDetails.id}
                                    createdOrderId={
                                        sidebarOrderTypeDetails.orderTypeId
                                    }
                                    onClose={() => {
                                        setSidebarOrderTypeDetails(undefined);
                                        fetchOrders();
                                    }}
                                />
                            </Box>
                        </Grid2>
                    )}

                    {sideBarOrderDetails && (
                        <Grid2 xs={3}>
                            <Box
                                fontSize="small"
                                sx={{
                                    p: 2,
                                    backgroundColor: "lightBlue.main",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <SidebarOrderDetails
                                    transportOrdersView
                                    onClose={() => {
                                        dispatch(setSideBarOrderDetails(null));
                                        fetchOrders();
                                    }}
                                />
                            </Box>
                        </Grid2>
                    )}
                </Grid2>
            </>
        </MainLayout>
    );
};

interface OrdersTableProps {
    orders: TransportOrderListItemDto[];
    handleOrderProducts?: (row) => void;
    handleOrderDetails?: (row) => void;
    handleOrderType?: (row: TransportOrderListItemDto) => void;
    disablePagination?: boolean;
    selectedSidebar: string;
    addForm: boolean;
    rowForEdit: TransportOrderListItemDto;
    setRowForEdit: (row: TransportOrderListItemDto) => void;
    setAddForm: (status: boolean) => void;
    fetchOrders: () => void;
}

export const defaultTableOrderItem = {
    name: "",
    vehicleId: "",
    employeeId: "",
    scheduledStartDateTime: "",
    scheduledEndDateTime: "",
    state: TransportOrderState.OrderCreated,
    transportDetails: "",
    senderBusinessId: "",
    orderTypeId: "",
    formData: "",
    formValues: "",
    senderCustomerId: "",
    receiverBusinessId: "",
    receiverCustomerId: "",
    driverComments: "",
    billingCustomerId: "",
    fixedPrice: 0,
    createdEmployeeId: "",
};

export const OrdersTable = ({
    orders,
    handleOrderProducts,
    handleOrderDetails,
    handleOrderType,
    disablePagination,
    addForm,
    rowForEdit,
    selectedSidebar,
    setAddForm,
    setRowForEdit,
    fetchOrders,
}: OrdersTableProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const employees = useAppSelector((state) => state.employees.employees);
    const employeeId = useAppSelector((state) => state.user.employeeId);
    const [showDeleteModal, setShowDeleteModal] = useState<string | undefined>(
        undefined
    );
    const [highlightedRowId, setHighlightedRowId] = useState<string | null>(null);
    const [shownTable, setShownTable] = useState<TableDef<TransportOrderListItemDto>>(
        () => ({
            columns: []
        })
    );
    const [ordersListShownColumnsPreviously,
        setOrdersListShownColumnsPreviously] = useState<string[] | null>(null);

    const formatTooltipText = (toolTipText: string | undefined) => {
        if (!toolTipText) {
            return "";
        }

        if (toolTipText.length > 200) {
            return `${toolTipText.slice(0, 200)}...`;
        }

        return toolTipText;
    };

    const table = useMemo<TableDef<TransportOrderListItemDto>>(
        () => ({
            columns: [
                {
                    order: 1,
                    label: t("common.status"),
                    accessor: "state",
                    id: "state",
                    renderCell: (val: TransportOrderState) => {
                        return <TransportOrderStateIndicator state={val} />;
                    },
                },
                {
                    order: 2,
                    label: t("common.orderNumber"),
                    accessor: "orderNumber",
                    id: "orderNumber",
                },
                {
                    order: 3,
                    label: t("transport.order.identifier"),
                    accessor: "name",
                    id: "name",
                },
                {
                    order: 4,
                    label: t("transport.order.customer"),
                    accessor: "senderName",
                    id: "senderName",
                },
                {
                    order: 5,
                    label: t("transport.order.receiver"),
                    accessor: "receiverName",
                    id: "receiverName",
                },
                {
                    order: 6,
                    label: t("transport.order.billingCustomer"),
                    accessor: "billingCustomerName",
                    id: "billingCustomerName",
                },
                {
                    order: 7,
                    label: t("reporting.fleet"),
                    accessor: "vehicleName",
                    id: "vehicleName",
                },
                {
                    order: 8,
                    label: t("transport.driver"),
                    accessor: "employeeName",
                    id: "employeeName",
                },
                {
                    order: 9,
                    label: t("transport.order.startTime"),
                    accessor: "scheduledStartDateTime",
                    id: "scheduledStartDateTime",
                    renderCell: (_, row) =>
                        getDateTime(row?.scheduledStartDateTime),
                },
                {
                    order: 10,
                    label: t("transport.order.endTime"),
                    accessor: "scheduledEndDateTime",
                    id: "scheduledEndDateTime",
                    renderCell: (_, row) =>
                        getDateTime(row?.scheduledEndDateTime),
                },
                {
                    order: 11,
                    label: t("common.created"),
                    accessor: "createdDateTime",
                    id: "createdDateTime",
                    renderCell: (_, row) =>
                        getDateTime(row?.createdDateTime),
                },
                {
                    order: 12,
                    label: t("transport.order.creator"),
                    accessor: "createdEmployeeId",
                    id: "createdEmployeeId",
                    renderCell: (_, row) =>
                        getEmployeeName(row?.createdEmployeeId, employees),
                },
                {
                    order: 13,
                    label: t("transport.products"),
                    accessor: "invoiceNewId",
                    id: "invoiceNewId",
                    renderCell: (val, row) => (
                        <div onClick={() => handleOrderProducts(row)}>
                            <span
                                style={{
                                    color: "dodgerblue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                            >
                                {`${row.products.length} kpl`}
                            </span>
                        </div>
                    ),
                },
                {
                    order: 14,
                    label: t("common.type"),
                    accessor: "formTitle",
                    id: "formTitle",
                    renderCell: (val, row) => (
                        <div onClick={() => handleOrderType(row)}>
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                            >
                                {t("transport.order.addOrderType")}
                            </span>
                        </div>
                    ),
                },
                {
                    order: 15,
                    label: t("common.additionalDetails"),
                    accessor: "transportDetails",
                    id: "transportDetails",
                    renderCell: (val, row) => (
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ maxWidth: "60px" }}
                            onClick={() => handleOrderDetails(row)}
                        >
                            <Tooltip title={i18n.t(formatTooltipText(val))}>
                                <IconButton disableRipple>
                                    {!val ? <InfoIcon color="gray" /> : <InfoIcon color="primary" />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    ),
                },
                {
                    order: 16,
                    label: t("common.edit"),
                    id: "actionsCol",
                    renderCell: (cell, row) =>
                        row.invoicingState !==
                            TransportOrderInvoicingState.InvoicedInternally &&
                        row.invoicingState !==
                            TransportOrderInvoicingState.InvoicedExternally ? (
                                <>
                                    <IconButton
                                        onClick={() =>
                                            !addForm && setRowForEdit(row)
                                    }
                                        size="small"
                                        color="primary"
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    {row.state ===
                                    TransportOrderState.OrderCreated && (
                                    <IconButton
                                        onClick={() =>
                                            setShowDeleteModal(row.id)
                                        }
                                        size="small"
                                        color="primary"
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    )}
                                </>
                            ) : null,
                },
            ],
        }),
        [employees, addForm]
    );

    const { ordersListShownColumns } = useAppSelector((state) => state.transportOrdersColumns);

    const formik = useFormik<TransportOrderItem>({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        initialValues: defaultTableOrderItem,
        validationSchema: transportPlanValidationSchema,
        onSubmit: async(values: TransportOrderItem) => {
            const payload = {
                name: values.name,
                vehicleId: values.vehicleId || null,
                employeeId: values.employeeId || null,
                scheduledStartDateTime: values.scheduledStartDateTime || null,
                scheduledEndDateTime: values.scheduledEndDateTime || null,
                state: values.state,
                transportDetails: values.transportDetails || null,
                senderCustomerId: values.senderCustomerId || null,
                receiverCustomerId: values.receiverCustomerId || null,
                driverComments: values.driverComments || null,
                billingCustomerId: values.billingCustomerId || null,
                fixedPrice: values.fixedPrice || null,
                createdEmployeeId: employeeId,
                orderTypeId: rowForEdit && rowForEdit.orderTypeId || null,
                formData: rowForEdit && rowForEdit.formData || null,
                formValues: rowForEdit && rowForEdit.formValues || null
            };

            if (rowForEdit) {
                delete Object.assign(payload, {
                    ["modifiedEmployeeId"]: payload["createdEmployeeId"],
                })["createdEmployeeId"];
                await editTransportOrder(payload, String(rowForEdit.id)).then(
                    () => {
                        setHighlightedRowId(String(rowForEdit.id));
                        setTimeout(() => setHighlightedRowId(null), 2000);
                    }
                );
            } else {
                await addTransportOrder(payload).then((newRow) => {
                    setHighlightedRowId(newRow.data.id);
                    setTimeout(() => setHighlightedRowId(null), 2000);
                });
            }

            setRowForEdit(null);
            setAddForm(false);
            store.customStore.dispatch(
                StoreActions.showSuccessMessage(t("transport.order.successMessage"))
            );
            fetchOrders();
        },
    });

    const { handleSubmit, setFieldValue, resetForm } = formik;

    useEffect(() => {
        if (addForm === false) {
            resetForm();
        }
    }, [addForm]);

    useEffect(() => {
        resetForm();
        if (rowForEdit) {
            for (const [key] of Object.entries(defaultTableOrderItem)) {
                void setFieldValue(key, rowForEdit[key] || undefined);
            }
        }
    }, [rowForEdit]);

    useEffect(() => {
        setShownTable(table);
        if (addForm || rowForEdit) {
            setOrdersListShownColumnsPreviously(ordersListShownColumns)
        }
        if (ordersListShownColumnsPreviously) {
            dispatch(updateOrdersListShownColumns(ordersListShownColumnsPreviously));
            setOrdersListShownColumnsPreviously(null);
        } else {
            let cols = [];
            if (ordersListShownColumns) {
                ordersListShownColumns.forEach(col => {
                    let tCol = table.columns.find((tc) => tc.id == col);
                    cols.push(tCol.id);
                });
            } else {
                table.columns.forEach(col => {
                    cols.push(col.id)
                });
            }
            dispatch(updateOrdersListShownColumns(cols));
        }
    }, [table]);

    useEffect(() => {
        if (shownTable.columns.length > 0 && ordersListShownColumns) {
            let updateShownTable = Object.assign({}, table);
            updateShownTable.columns = [];
            ordersListShownColumns.forEach(col => {
                updateShownTable.columns.push(
                    table.columns.find((tc) => tc.id == col)
                );
            });
            updateShownTable.columns.sort((a, b) => a.order - b.order);
            setShownTable(updateShownTable);
        }
    }, [ordersListShownColumns]);

    if (!ordersListShownColumns) {
        return null;
    }

    return (
        <>
            <Stack
                spacing={0}
                mb={0}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid2>
                    {!rowForEdit && !addForm && (
                        <Button
                            variant="save"
                            startIcon={<AddIcon />}
                            onClick={() => setAddForm(true)}
                        >
                            {t("transport.order.newOrder")}
                        </Button>
                    )}

                    {(addForm || rowForEdit) && (
                        <Button
                            sx={{ marginLeft: "20px" }}
                            variant="contained"
                            onClick={() => {
                                setAddForm(false);
                                setRowForEdit(null);
                            }}
                        >
                            {t("common.cancel")}
                        </Button>
                    )}
                </Grid2>
                <Grid2>
                    <MuiMenu
                        label={
                            <SettingsIcon
                                sx={{ color: theme.palette.common.black }}
                            />
                        }
                        keepOpenAfterSelect
                        items={[
                            ...table.columns.map((x) => {
                                return {
                                    label: x.label,
                                    icon: ordersListShownColumns.includes(
                                        x.id
                                    ) ? (
                                        <CheckBoxIcon color="secondary" />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon color="secondary" />
                                    ),
                                    onClick: () => {
                                        if (
                                            ordersListShownColumns.includes(x.id)
                                        ) {
                                            dispatch(
                                                updateOrdersListShownColumns(
                                                    ordersListShownColumns.filter(
                                                        (y) => y !== x.id
                                                    )
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                updateOrdersListShownColumns(
                                                    [
                                                        ...ordersListShownColumns,
                                                        x.id,
                                                    ]
                                                )
                                            );
                                        }
                                    },
                                };
                            }),
                        ]}
                    />
                </Grid2>
            </Stack>
            <TableWrapper
                tableDef={shownTable}
                addForm={addForm}
                selectedSidebar={selectedSidebar}
                data={orders}
                disablePagination={disablePagination}
                rowForEdit={rowForEdit}
                highlightedRowId={highlightedRowId}
                handleSubmit={handleSubmit}
            >
                <TransportOrdersMainForm
                    formik={formik}
                    isEdit={!!rowForEdit}
                />
            </TableWrapper>
            {showDeleteModal && (
                <ConfirmationDialog
                    title={""}
                    text={i18n.t(
                        Translations.AreYouSureWantDeleteStorageProduct
                    )}
                    show={true}
                    onYes={async() => {
                        await deleteTransportOrder(showDeleteModal);
                        store.customStore.dispatch(
                            StoreActions.showSuccessMessage(
                                Translations.SaveSuccess
                            )
                        );
                        fetchOrders();
                        setShowDeleteModal(undefined);
                    }}
                    onNo={() => setShowDeleteModal(undefined)}
                    onCancel={() => setShowDeleteModal(undefined)}
                />
            )}
        </>
    );
};
