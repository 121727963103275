import {
    IconButton,
    Typography,
    Box,
    Tooltip,
    Button,
    Select,
    MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";

import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";
import {
    RootState,
    useAppDispatch,
    useAppSelector,
} from "../../../framework/customStore";

import { Translations } from "../../../models/translations";
import Grid2 from "@mui/material/Unstable_Grid2";
import DynamicForm from "../../orderTypes/dynamicForm";
import { fetchTransportOrderTypes } from "../../../store/orderTypesSlice";
import { OrderTypeItem } from "../../../models/orderType/orderTypeItem";
import {
    editTransportOrder,
    getTransportOrder,
} from "../../../services/transportOrderService";
import { ConfirmationDialogType } from "../../../models/store/storeTypes";
import {useTranslation} from "react-i18next";

interface ISidebarOrderDetailsProps {
    onClose: () => void;
    sidebarOrdersDataChanged?: boolean;
    setSidebarOrdersDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    transportOrdersView?: boolean;
    orderTypeId: string;
    createdOrderId?: string;
}

export const SidebarOrderType = ({
    onClose,
    orderTypeId,
    createdOrderId,
}: ISidebarOrderDetailsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const orderTypes = useAppSelector(
        (state: RootState) => state.orderTypes.orderTypes
    );
    const [selectedOrderType, setSelectedOrderType] = useState(null);
    const [trasnsportOrder, setTransportOrder] = useState(null);
    const employeeId = useAppSelector((state) => state.user.employeeId);

    const [state, setState] = useState({
        orderTypeId: orderTypeId || "",
        schema: "{}",
        uischema: "{}",
        formValues: "{}",
    });

    useEffect(() => {
        const fetchOrderType = async() => {
            try {
                const response = await getTransportOrder(orderTypeId);
                console.log(response);
                const orderType = response.data;
                setTransportOrder(orderType);
                setState({
                    orderTypeId: orderTypeId,
                    schema: orderType.formData || "{}",
                    uischema: "{}", // todo
                    formValues: orderType.formValues || "{}",
                });
            } catch (error) {
                console.error("Error fetching order type:", error);
            }
        };
        fetchOrderType().catch((error) =>
            console.error("Error in fetchOrderType:", error)
        );
    }, []);

    useEffect(() => {
        dispatch(fetchTransportOrderTypes() as any);
    }, []);

    const onSubmit = async() => {
        const payload = {
            ...trasnsportOrder,
            formData: state.schema,
            formValues: state.formValues,
            orderTypeId: selectedOrderType || createdOrderId,
            createdEmployeeId: employeeId,
            modifiedEmployeeId: employeeId,
        };
        await editTransportOrder(payload, String(orderTypeId)).then(() => {});
        store.customStore.dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
            StoreActions.showSuccessMessage(t("transport.order.successMessage"))
        );
        onClose();
    };

    const handleSelectedType = (selectedType: OrderTypeItem) => {
        setState({
            orderTypeId: orderTypeId,
            schema: selectedType.formSchema.schemaDetails,
            uischema: "{}",
            formValues: "{}",
        });
    };

    const handleOrderType = (data: any, selectedType: OrderTypeItem) => {
        if (createdOrderId) {
            store.customStore.dispatch(
                StoreActions.setConfirmation(
                    ConfirmationDialogType.Warning,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-argument
                    t("transport.order.confirmationMessage"),
                    // Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                    null,
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    () => {
                        setSelectedOrderType(data);
                        handleSelectedType(selectedType);
                    },
                    () => null,
                    () => null
                )
            );
        } else {
            setSelectedOrderType(data);
            handleSelectedType(selectedType);
        }
    };

    return (
        <Stack spacing={2} sx={{ width: "100%" }} fontSize="small">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h2">{t("transport.order.orderOrderTypes")}</Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="small"
                        onClick={() => onClose()}
                        key="btn-copy"
                    >
                        <CloseIcon color="primary" />
                    </IconButton>
                </Box>
            </Stack>
            <Grid2>
                <span>Tilaustyyppi</span>
                <Select
                    id="order"
                    name="order"
                    displayEmpty
                    value={selectedOrderType || ""}
                    sx={{
                        width: "100%",
                        color: "#000",
                    }}
                    onChange={(e) => {
                        const selectedType = orderTypes.find(
                            (item) => item.orderTypeId === e.target.value
                        );
                        handleOrderType(e.target.value, selectedType);

                    }}
                >
                    {orderTypes.map((item) => (
                        <MenuItem
                            key={item.orderTypeId}
                            value={item.orderTypeId}
                        >
                            <span>{item.title}</span>
                        </MenuItem>
                    ))}
                </Select>
            </Grid2>
            <Grid2 container sx={{ padding: "1rem", marginTop: 0 }}>
                <Grid2 xs={12}>
                    <DynamicForm
                        schema={state.schema ? JSON.parse(state.schema) : {}}
                        uischema=""
                        formValues={
                            state.formValues ? JSON.parse(state.formValues) : {}
                        }
                        onChange={(newFormData) => {
                            console.log(newFormData);
                            setState({
                                ...state,
                                formValues: JSON.stringify(newFormData),
                            });
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onSubmit={onSubmit}
                    >
                        <Grid2
                            container
                            display="flex"
                            alignItems="center"
                            justifyContent="end"
                        >
                            <Button variant="contained" type="submit">
                                {Translations.Save}
                            </Button>
                        </Grid2>
                    </DynamicForm>
                </Grid2>
            </Grid2>
        </Stack>
    );
};
