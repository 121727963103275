import { Stack, TextField, TextFieldProps } from "@mui/material";
import { fi } from "date-fns/locale";
import React, { forwardRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Translations } from "../../models/translations";

const locales = {
    fi
};

interface MuiTextFieldCustomInputProps {
    // All other props except this need to be aplied to the input element
    extraProps: {
        events: {
            onClear: () => void;
        };
        textFieldProps: TextFieldProps;
    };
}

const MuiTextFieldCustomInput = forwardRef<unknown, MuiTextFieldCustomInputProps>((props, ref) => {
    const { extraProps, ...rest } = props;
    return (
        <TextField
            className={props.extraProps.textFieldProps.fullWidth ? "field-width-fluid" : "field-width-normal"}
            autoComplete="off"
            inputRef={ref}
            inputProps={{
                ...rest
            }}
            InputProps={{
                inputRef: ref,
            }}
            {...extraProps.textFieldProps}
        />
    );
});

interface MuiDatePickerProps extends Omit<ReactDatePickerProps, "value" | "onChange"> {
    // Shorthand for label, since it's so common .
    label?: string;
    // Override value and onChange to align with common convention.
    value: Date | null;
    onChange?: (val: Date | null) => void;

    // Extra props that are not passed to the DatePicker itself, but customize other things.
    extraProps?: {
        textFieldProps?: TextFieldProps;
        small?: boolean;
    };
}

/**
 * react-dapicker, but with Material UI styling, to fit in with other fields and allow some customization.
 * TODO: pressing tab should move to next input instead of the calendar widget
 */
export const MuiDatePicker = (props: MuiDatePickerProps) => {
    const { label, value, onChange, extraProps, ...rest } = props;

    const handleChange = (val: Date | null) => {
        onChange?.(val);
    };


    return (
        <DatePicker
            dateFormat={`dd.MM.yyyy${props.showTimeSelect ? " HH:mm" : ""}`}
            locale={locales[appConfig.culture]}
            showWeekNumbers
            {...rest}
            wrapperClassName="mui-datepicker"
            selected={value}
            onChange={handleChange}
            timeInputLabel="foo"
            timeCaption={Translations.Time}
            customInput={
                <MuiTextFieldCustomInput
                    extraProps={{
                        textFieldProps: {
                            size: extraProps?.small ? "small" : "medium",
                            label,
                            ...extraProps?.textFieldProps
                        },
                        events: {
                            onClear: () => handleChange(null)
                        }
                    }}
                />}
        />
    );
};

export type timeRangePickerValue = [Date | null, Date | null]

interface MuiDateTimeRangePickerProps {
    value: timeRangePickerValue;
    onChange: (val: timeRangePickerValue) => void;
    labels?: [string, string];
    small?: boolean;
}

export const MuiDateTimeRangePicker = (props: MuiDateTimeRangePickerProps) => {
    const { value, onChange } = props;

    const handleStartChange = (start: Date | null) => {
        onChange?.([start, value[1]]);
    };

    const handleEndChange = (end: Date | null) => {
        onChange?.([value[0], end]);
    };

    return (
        // TODO would be nicer if inputs could be inside a single container
        <Stack direction="row" alignItems="center">
            <MuiDatePicker
                dateFormat="dd.MM.yyyy HH:mm"
                value={value[0]}
                selectsStart
                startDate={value[0]}
                endDate={value[1]}
                onChange={handleStartChange}
                showTimeSelect
                extraProps={{ small: props.small, textFieldProps: { fullWidth: true, label: props.labels?.[0] } }}
            />
            <span>&nbsp;-&nbsp;</span>
            <MuiDatePicker
                dateFormat="dd.MM.yyyy HH:mm"
                value={value[1]}
                selectsEnd
                startDate={value[0]}
                endDate={value[1]}
                onChange={handleEndChange}
                showTimeSelect
                extraProps={{ small: props.small, textFieldProps: { fullWidth: true, label: props.labels?.[1] } } }
            />
        </Stack>
    );
};

interface MuiDateRangePickerProps {
    value: timeRangePickerValue;
    onChange: (val: timeRangePickerValue) => void;
    labels?: [string, string];
    small?: boolean;
}

export const MuiDateRangePicker = (props: MuiDateRangePickerProps) => {
    const { value, onChange } = props;
    const handleStartChange = (start: Date | null) => {
        onChange?.([start, value[1]]);
    };

    const handleEndChange = (end: Date | null) => {
        onChange?.([value[0], end]);
    };

    return (
        // TODO would be nicer if inputs could be inside a single container
        <Stack direction="row" alignItems="center">
            <MuiDatePicker
                dateFormat="dd.MM.yyyy"
                value={value[0]}
                selectsStart
                startDate={value[0]}
                endDate={value[1]}
                onChange={handleStartChange}
                extraProps={{ small: props.small, textFieldProps: { fullWidth: true, label: props.labels?.[0] } }}
            />
            <span>&nbsp;-&nbsp;</span>
            <MuiDatePicker
                dateFormat="dd.MM.yyyy"
                value={value[1]}
                selectsEnd
                startDate={value[0]}
                endDate={value[1]}
                onChange={handleEndChange}
                extraProps={{ small: props.small, textFieldProps: { fullWidth: true, label: props.labels?.[1] } } }
            />
        </Stack>
    );
};

