import { ApiResponse, apiCall } from "./apiClient";
import { TransportOrderDetailsDto, TransportOrderListItemDto, TransportOrderListQueryParameters, TransportOrderInvoicingStateChangeDto, TransportOrderItem } from "../models/transport/transportOrder";
import { TransportTaskDetailsDto, TransportTaskType } from "../models/transport/transport";

export const getTransportOrders = (qsp: TransportOrderListQueryParameters): Promise<ApiResponse<TransportOrderListItemDto[]>> => {
    return apiCall<TransportOrderListItemDto[]>("transportOrders", "GET", qsp);
};

export const getTransportOrder = (id: string): Promise<ApiResponse<TransportOrderDetailsDto>> => {
    return apiCall<TransportOrderDetailsDto>(`transportOrders/${id}`, "GET");
};

export const addTransportOrder = (data: TransportOrderItem): Promise<ApiResponse<TransportOrderItem>> => {
    return apiCall<TransportOrderItem>("transportOrders", "POST", data);
};

export const editTransportOrder = (data: TransportOrderItem, transportOrderId: string): Promise<ApiResponse<TransportOrderItem>> => {
    return apiCall<TransportOrderItem>(`TransportOrders/${transportOrderId}`, "PUT", data);
};

export const deleteTransportOrder = (transportOrderId: string): Promise<ApiResponse<TransportOrderItem>> => {
    return apiCall<TransportOrderItem>(`TransportOrders/${transportOrderId}`, "DELETE");
};

export const createTransportOrder = (data: TransportOrderDetailsDto): Promise<ApiResponse<TransportOrderDetailsDto>> => {
    return apiCall<TransportOrderDetailsDto>("TransportOrders", "POST", data);
};

export const getLatestTask = async(transportOrderId: string) => {
    const response = await apiCall<TransportTaskDetailsDto>(`TransportOrders/${transportOrderId}/GetLatestTask`, "GET");
    return response.data;
};

export const removeLatestTaskByType = async(transportOrderId: string, taskType: TransportTaskType) => {
    const response = await apiCall<void>(`TransportOrders/${transportOrderId}/RemoveLatestTask/${taskType}`, "DELETE", { taskType });
    return response.data;
};
export const updateTransportOrderInvoicingState = (id: string, invoicingState: TransportOrderInvoicingStateChangeDto) => {
    return apiCall(`TransportOrders/${id}/invoicingState`, "PUT", invoicingState);
};
