import React from "react";
import Form from "@rjsf/core";
import defaultValidator from "@rjsf/validator-ajv8";

interface DynamicFormProps {
    schema: any;
    uischema: any;
    children: React.ReactNode;
    formValues?: any;
    onSubmit: (formData: any) => void;
    onChange: (formData: any) => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
    schema,
    uischema,
    formValues,
    onSubmit,
    onChange,
    children,
}) => {
    const handleSubmit = (data: any) => {
        onSubmit(data);
    };

    const handleChange = (data: any) => {
        onChange(data);
    };

    const combinedUiSchema = {
        ...uischema,
        "ui:submitButtonOptions": { norender: true },
    };

    return (
        <Form
            schema={schema}
            uiSchema={combinedUiSchema}
            formData={formValues}
            onSubmit={(e) => handleSubmit(e.schema)}
            onChange={(e) => handleChange(e.formData)}
            validator={defaultValidator}
        >
            {children}
        </Form>
    );
};

export default DynamicForm;
