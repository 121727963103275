import { Stack } from "@mui/system";
import { Box, IconButton, MenuItem, Select, Typography, FormControl, OutlinedInput, InputAdornment, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { getTransportOrderProducts } from "../../../services/transportOrderProductsService";
import { editTransportOrder, getTransportOrder } from "../../../services/transportOrderService";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TransportOrderProductListItemDto } from "../../../models/transport/transportOrderProduct";
import { object, array, string, number } from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAppSelector } from "../../../framework/customStore";
import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";

interface ISidebarOrderProductsProps {
    transportOrderId: string;
    onClose: () => void;
}

export const SidebarOrderProducts = ({ transportOrderId, onClose } : ISidebarOrderProductsProps) => {
    const { t } = useTranslation();
    const [transportOrder, setTransportOrder] = useState(null);
    const [products, setProducts] = useState<TransportOrderProductListItemDto[]>([]);
    const employeeId = useAppSelector((state) => state.user.employeeId);
    const MAX_QUANTITY: number = 9999;

    const validationSchema = object().shape({
        transportProducts: array().of(
            object().shape({
                id: string().required(t("validation.fieldIsRequired", { field: t("transport.order.productName") })),
                quantity: number()
                    .min(1, t("validation.fieldMinValue", { field: t("transport.order.quantity"), value: 1 }))
                    .max(MAX_QUANTITY, t("validation.fieldMaxValue", { field: t("transport.order.quantity"), value: MAX_QUANTITY }))
                    .required(t("validation.fieldIsRequired", { field: t("transport.order.quantity") }))
            })
        )
    });

    const handleSubmit = async(values: { transportProducts: { id: string; quantity: number }[] }, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        try {
            const payload = {
                ...transportOrder,
                products: values.transportProducts.map(({ id, quantity }) => ({ id, quantity })),
                createdEmployeeId: employeeId,
                modifiedEmployeeId: employeeId
            };

            await editTransportOrder(payload, transportOrderId);
            store.customStore.dispatch(
                StoreActions.showSuccessMessage(t("transport.order.changesSavedSuccessfully"))
            );
            onClose();
        } catch (error) {
            console.error("Error updating transport order:", error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        const fetchTransportOrder = async() => {
            try {
                const response = await getTransportOrder(transportOrderId);
                const transportOrder = response.data;
                setTransportOrder(transportOrder);
            } catch (error) {
                console.error("Error fetching transport order:", error);
            }
        };
        fetchTransportOrder().catch((error) =>
            console.error("Error in fetchTransportOrder:", error)
        );
    }, []);

    useEffect(() => {
        const fetchOrderProducts = async() => {
            try {
                const response = await getTransportOrderProducts();
                const products = response.data;
                setProducts(products);
            } catch (error) {
                console.error("Error fetching order products:", error);
            }
        };
        fetchOrderProducts().catch((error) =>
            console.error("Error in fetchOrderProducts:", error)
        );
    }, []);

    return (
        <Formik
            initialValues={{
                transportProducts: transportOrder?.products.length > 0 ? transportOrder?.products : [{ id: "", quantity: 0 }],
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, handleSubmit }) => (
                <Form>
                    <Stack spacing={2} sx={{ width: "100%" }} fontSize="small">
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h2">{t("transport.order.productsOnTheOrder")}</Typography>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <IconButton size="small" onClick={onClose}>
                                    <CloseIcon color="primary" />
                                </IconButton>
                            </Box>
                        </Stack>

                        <Grid2>
                            {values.transportProducts.map((product, index) => (
                                <Stack key={index} direction="row" alignItems="center" spacing={2} sx={{ width: "100%" }} className={"mb-2"}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <span>{t("transport.order.productName")}</span>
                                        <Field as={Select}
                                            name={`transportProducts.${index}.id`}
                                            displayEmpty
                                            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                                const selectedProductId = e.target.value as string;
                                                setFieldValue(`transportProducts.${index}.id`, selectedProductId);
                                                setFieldValue(`transportProducts.${index}.quantity`, 0);
                                            }}
                                        >
                                            <MenuItem value="" />
                                            {products.map((p) => (
                                                <MenuItem key={p.id} value={p.id}>
                                                    {p.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <ErrorMessage name={`transportProducts.${index}.id`} component="div" style={{ color: "red", fontSize: "12px" }} />
                                    </FormControl>
                                    <FormControl sx={{ width: "100px" }}>
                                        <span>{t("transport.order.quantity")}</span>
                                        <Field
                                            as={OutlinedInput}
                                            name={`transportProducts.${index}.quantity`}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`transportProducts.${index}.quantity`, e.target.value)
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {products.find((p) => p.id === product.id)?.unitName || "-"}
                                                </InputAdornment>
                                            }
                                            sx={{ backgroundColor: "white" }}
                                        />
                                        <ErrorMessage name={`transportProducts.${index}.quantity`} component="div" style={{ color: "red", fontSize: "12px" }} />
                                    </FormControl>

                                    <IconButton onClick={() => setFieldValue("transportProducts", values.transportProducts.filter((_, i) => i !== index))} sx={{ color: "gray" }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            ))}

                            <Button
                                variant="text"
                                startIcon={<AddIcon />}
                                onClick={() => setFieldValue("transportProducts", [...values.transportProducts, { id: "", quantity: 0 }])}
                                sx={{ mt: 2, alignSelf: "flex-start" }}
                            >
                                {t("common.addNew")}
                            </Button>

                            <Grid2 container direction="row" justifyContent="flex-end" alignItems="center" sx={{ marginLeft: "auto" }}>
                                <Button variant="contained" type="submit" onClick={() => handleSubmit()}>
                                    {t("common.save")}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};
