import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";
import React, { memo } from "react";
import { Base } from "../../../framework/base";
import { HazardousMaterialClass } from "../../../models/transport/parcel";
import { TransportOrderDetailsDto, TransportOrderState, transportOrderStates } from "../../../models/transport/transportOrder";
import { TransportPlanState, transportPlanStates } from "../../../models/transport/transportPlan";
import { EmployeeListItemDto } from "../../../models/employee/employee";

export function transportOrderPickupAddress(item: TransportOrderDetailsDto): string {
    return [item.pickUpAddress, item.pickUpZipCode, item.pickUpCity].filter(s => s).join(" ");
}

export function transportOrderDeliveryAddress(item: TransportOrderDetailsDto): string {
    return [item.deliveryAddress, item.deliveryZipCode, item.deliveryCity].filter(s => s).join(" ");
}

const getDayFormat = () => new Intl.DateTimeFormat(appConfig.culture ?? "fi", {
    weekday: "short",
    day: "numeric",
    month: "numeric",
});

const getTimeFormat = () => new Intl.DateTimeFormat(appConfig.culture ?? "fi", {
    hour: "numeric",
    minute: "numeric"
});

type transportDate = string | Date | null | undefined;

function dateParts(date: transportDate): {day: string; time: string} | undefined {
    if (!date) return undefined;
    if (typeof date === "string") {
        date = new Date(date);
    }
    return {
        day: Base.capitalizeFirstChar(getDayFormat().format(date)),
        time: getTimeFormat().format(date)
    };
}

export function transportDateTimeRange(start: transportDate, end: transportDate): string {
    const startDate = dateParts(start);
    const endDate = dateParts(end);

    if (startDate && endDate && startDate?.day === endDate?.day) {
        return `${startDate.day} ${startDate.time}-${endDate.time}`;
    }

    return [startDate?.day, startDate?.time, " - ", endDate?.day, endDate?.time].filter(s => s).join(" ");
}

// TODO should find a better way to get these
export function getHazardousMaterialClasses(): {name: string; value: string}[] {
    return Object.keys(HazardousMaterialClass).filter((v) => isNaN(Number(v))).map(key => ({
        name: key,
        value: HazardousMaterialClass[key]
    }));
}

export const TransportOrderStateIndicator = memo((props: {state: TransportOrderState}) => {
    const state = transportOrderStates[props.state];
    return (
        <Tooltip title={state.name} disableInteractive>
            <CircleIcon fontSize="small" htmlColor={state.color} />
        </Tooltip>
    );
});

export const TransportPlanStateIndicator = memo((props: {state: TransportPlanState}) => {
    const state = transportPlanStates[props.state];
    return (
        <Tooltip title={state.name} disableInteractive>
            <CircleIcon fontSize="small" htmlColor={state.color} className="mr-1"/>
        </Tooltip>
    );
});

export const getDate = (date: string) => {
    if(!date) return "-";
    return Base.dayjsToDateStr(date);
};

export const getDateTime = (date: string) => {
    if(!date) return "-";
    return Base.dayjsDateToDateTimeStr(date);
};

export const getEmployeeName = (employeeId: string, employees: EmployeeListItemDto[]) => {
    if(!employeeId) return "-";
    const employee = employees.find(e => e.id === employeeId);
    return `${employee?.firstName} ${employee?.lastName}`;
};
