import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    TransportOrderListItemDto,
    TransportOrderState,
} from "../../models/transport/transportOrder";

interface TransportOrdersState {
    timeRange: [Date, Date];
    createdRange: [Date, Date];
    vehicleId: string;
    billingCustomerId: string | null;
    senderCustomer: string | null;
    receiverCustomer: string | null;
    selectedOrder: TransportOrderListItemDto | null;
    keyword: string;
    selectedOrderStates: Record<TransportOrderState, boolean>;
    ordersDataChanged: boolean;
    orderType: string | null;
}

const initialState: TransportOrdersState = {
    timeRange: [
        null,
        null,
    ],
    createdRange: [
        null,
        null,
    ],
    vehicleId: null,
    billingCustomerId: null,
    senderCustomer: null,
    receiverCustomer: null,
    selectedOrder: null,
    keyword: "",
    selectedOrderStates: {
        //[TransportOrderState.OrderReceived]: true,
        [TransportOrderState.OrderCreated]: true,
        //[TransportOrderState.Planned]: true,
        [TransportOrderState.PickedUp]: true,
        [TransportOrderState.Delivered]: true,
    },
    ordersDataChanged: false,
    orderType: null,
};

export const transportOrdersSlice = createSlice({
    name: "transportOrders",
    initialState,
    reducers: {
        setTimeRange(state, action: PayloadAction<[Date, Date]>) {
            state.timeRange = action.payload;
        },
        setCreatedRange(state, action: PayloadAction<[Date, Date]>) {
            state.createdRange = action.payload;
        },
        setBillingCustomer(state, action: PayloadAction<string>) {
            state.billingCustomerId = action.payload;
        },
        setSenderCustomer(state, action: PayloadAction<string>) {
            state.senderCustomer = action.payload;
        },
        setReceiverCustomer(state, action: PayloadAction<string>) {
            state.receiverCustomer = action.payload;
        },
        setKeyword(state, action: PayloadAction<string>) {
            state.keyword = action.payload;
        },
        setVehicle(state, action: PayloadAction<string>) {
            state.vehicleId = action.payload;
        },
        toggleOrderStateSelected(
            state,
            action: PayloadAction<TransportOrderState>
        ) {
            state.selectedOrderStates[action.payload] =
                !state.selectedOrderStates[action.payload];
        },
        setOrdersDataChanged(state, action: PayloadAction<boolean>) {
            state.ordersDataChanged = action.payload;
        },
        updateOrderState(state, action: PayloadAction<TransportOrderState>) {
            if (state.selectedOrder) {
                state.selectedOrder.state = action.payload;
            }
        },
        resetOrderFilters() {
            return { ...initialState };
        },
        setOrderType(state, action: PayloadAction<string>) {
            state.orderType = action.payload;
        },
    },
});

export const {
    setTimeRange,
    setCreatedRange,
    setBillingCustomer,
    setSenderCustomer,
    setReceiverCustomer,
    setKeyword,
    setVehicle,
    resetOrderFilters,
    toggleOrderStateSelected,
    setOrdersDataChanged,
    updateOrderState,
    setOrderType
} = transportOrdersSlice.actions;

export const transportOrdersReducer = transportOrdersSlice.reducer;
