import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransportOrdersColumnsState {
    ordersListShownColumns: string[] | null;
}

const initialState: TransportOrdersColumnsState = {
    ordersListShownColumns: null,
};

export const transportOrdersColumnsSlice = createSlice({
    name: "transportOrdersColumns",
    initialState,
    reducers: {
        updateOrdersListShownColumns: (state, action: PayloadAction<string[]>) => {
            state.ordersListShownColumns = action.payload;
        },
    },
});

export const {
    updateOrdersListShownColumns,
} = transportOrdersColumnsSlice.actions;

export const transportOrdersColumnsReducer = transportOrdersColumnsSlice.reducer;
