import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { FormikFieldProps, showApiError } from "../../framework/formUtils";
import { AutocompleteOption, AutocompleteValue, AutocompleteWrapper } from "../../framework/muiAutocomplete";
import { fetchOrderTypes } from "../../../services/orderTypesService";

interface OrderTypeProps extends Partial<FormikFieldProps> {
    small?: boolean;
    required?: boolean;
    label?: string;
    fullWidth?: boolean;
    disabled?: boolean;
}

export const OrderTypeSelect = (props: OrderTypeProps) => {
    const [orderTypeOptions, setOrderTypeOptions] = useState<AutocompleteOption[]>([]);
    const [loading, setLoading] = useState(false);
    const { value, small, onChange, ...rest } = props;

    useEffect(() => {
        let orderTypesfetched = false;
        const getOrderTypes = async() => {
            await fetchOrderTypes()
                .then((res) => {
                    const orderTypes = res?.data
                        .map(v => ({ id: v.orderTypeId, title: v?.title }))
                        .sort((a, b) => a.title > b.title ? 1 : -1);
                        setOrderTypeOptions(orderTypes);
                    setLoading(false);
                    orderTypesfetched = true;
                })
                .catch(() => showApiError(Translations.FetchFailed));
        };
        if(orderTypeOptions?.length === 0 && !orderTypesfetched) {
            setLoading(true);
            getOrderTypes().catch(() => showApiError(Translations.FetchFailed));
        }
    }, [orderTypeOptions]);

    const handleChange = (val: AutocompleteValue | null) => {
        if (val === null) {
            onChange?.(null);
        }
        else if ("id" in val) {
            onChange?.(val?.id);
        }
    };

    return (
        <AutocompleteWrapper
            label={props.label ?? Translations.Customer}
            value={value}
            size={small ? "small" : "medium"}
            options={orderTypeOptions}
            onChange={handleChange}
            loading={loading}
            required={props.required}
            textFieldProps={{ ...rest }}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
        />
    );
};
